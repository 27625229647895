import * as React from "react";
import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";

import { cn } from "@/lib/utils";

const buttonVariants = cva(
  "group inline-flex   items-center gap-3 whitespace-nowrap  text-center  font-default font-semibold ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 ",
  {
    variants: {
      variant: {
        default:
          "bg-primary text-primary-foreground  hover:bg-secondary hover:font-semibold hover:text-secondary-foreground ",
        defaultOutline:
          "box-border border  border-solid border-primary bg-transparent text-primary hover:bg-primary  hover:text-primary-foreground",
        popover:
          "bg-popover  text-popover-foreground hover:bg-secondary hover:text-popover-foreground",
        popoverOutline:
          "box-border  border border-solid border-popover bg-transparent  text-popover hover:bg-popover hover:text-primary dark:hover:text-primary-foreground",

        destructive:
          "bg-destructive  text-destructive-foreground hover:bg-destructive/90",
        outline:
          "box-border  border border-primary  hover:bg-primary hover:text-primary-foreground",
        secondary:
          "bg-secondary text-secondary-foreground hover:bg-secondary/80",
        ghost: "hover:bg-accent hover:text-accent-foreground",
        link: "text-primary underline-offset-4 hover:underline",
      },
      size: {
        default:
          "max-h-20 min-h-max px-6 py-7 text-CTALarge tracking-[_0.24px]",
        sm: "max-h-11 min-h-max p-4 py-2 text-lg",
        icon: "h-10 w-10 p-0",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  },
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : "button";
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      />
    );
  },
);
Button.displayName = "Button";

export { Button, buttonVariants };
