import * as React from "react";
import { Moon, Sun } from "lucide-react";

import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  DropdownMenuPortal,
} from "@/components/ui/dropdown-menu";

export function ModeToggle() {
  const getInitialTheme = React.useCallback(() => {
    if (typeof window !== "undefined") {
      const storedTheme = localStorage.getItem("theme");
      if (storedTheme) {
        return storedTheme;
      }
    }
    return "light";
  }, []);

  const [theme, setThemeState] = React.useState<"light" | "dark" | "system">(
    // @ts-ignore
    getInitialTheme,
  );

  const isDarkMode = React.useCallback(
    () => document.documentElement.classList.contains("dark"),
    [],
  );

  React.useEffect(() => {
    setThemeState(isDarkMode() ? "dark" : "light");
  }, []);

  React.useEffect(() => {
    const isDark =
      theme === "dark" ||
      (theme === "system" &&
        window.matchMedia("(prefers-color-scheme: dark)").matches);
    document.documentElement.classList[isDark ? "add" : "remove"]("dark");
    localStorage.setItem("theme", theme);
  }, [theme]);

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="outline" size="icon" className="gap-0 rounded-none">
          <Sun className=" relative bottom-[7px] h-[2rem] w-[2rem] rotate-0 scale-100   transition-all dark:-rotate-90 dark:scale-0" />
          <Moon className="relative -bottom-2 h-[2rem] w-[2rem] rotate-90 scale-0  transition-all  dark:rotate-0 dark:scale-100" />
          <span className="sr-only">Mudar Tema</span>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuPortal>
        <DropdownMenuContent align="end">
          <DropdownMenuItem onClick={() => setThemeState("light")}>
            Claro
          </DropdownMenuItem>
          <DropdownMenuItem onClick={() => setThemeState("dark")}>
            Escuro
          </DropdownMenuItem>
          <DropdownMenuItem onClick={() => setThemeState("system")}>
            Sistema
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenuPortal>
    </DropdownMenu>
  );
}
